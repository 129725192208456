import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This is a mermaid diagram…`}</p>
    </PageDescription>
    <pre><code parentName="pre" {...{
        "className": "language-mermaid"
      }}>{`gantt
  title My Product Roadmap
  dateFormat  YYYY-MM-DD
  section Cool Feature
  A task           :a1, 2022-02-25, 30d
  Another task     :after a1, 20d
  section Rad Feature
  Task in sequence :2022-03-04, 12d
  Task, No. 2      :24d
`}</code></pre>
    <h2>{`Overall`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-mermaid"
      }}>{`gantt
    title Digital Transformation Journey
    dateFormat  YYYY-MM-DD
    section Discovery
    Collect Inventory of Legacy Apps           :a1, 2020-01-01, 20d
    Map to Existing Target Apps     :a2, after a1, 10d
    Create Target Architecture     :a3, after a2, 20d
    section Implementation
    Project A      :after a3, 12d
    Project B      :after a3, 12d
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      